.banner {
  background-color: #3498db; /* Adjust the color to your preference */
  color: white; /* Text color */
  font-family: 'Quicksand', sans-serif; /* Stylish font family */
  font-size: 24px; /* Larger font size */
  padding: 20px; /* Padding around the text */
  text-align: center; /* Center-align the text */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle box shadow */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .banner {
    font-size: 20px; /* Adjust font size for smaller screens */
    padding: 15px; /* Adjust padding for smaller screens */
  }
}

/* Adjust styles for smaller mobile screens */
@media (max-width: 480px) {
  .banner {
    font-size: 16px; /* Reduce font size for smaller mobile screens */
    padding: 10px; /* Adjust padding for smaller mobile screens */
  }
}
@media (max-width: 375px) {
  .banner {
    font-size: 10px; /* Reduce font size for smaller mobile screens */
    margin-top: 75px;
    padding: 10px; /* Adjust padding for smaller mobile screens */
  }
}
@media (max-width: 320px) {
  .banner {
    font-size: 10px; /* Reduce font size for smaller mobile screens */
    margin-top: 115px;
    padding: 10px; /* Adjust padding for smaller mobile screens */
  }
}
.YoutubeLinkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 80vh;
  // padding-bottom: 56.25%; /* 16:9 aspect ratio */

  .YoutubeLink {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 12%; /* 16:9 aspect ratio */
    padding-top: 5%;
    background-color: var(--youtube-thumbnail-background-color);
    border-radius: 5px;
    overflow: hidden;

    .YoutubeLinkThumbnail {
      width: 100%;
      padding-bottom: 56.25%; /* 16:9 aspect ratio */
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: var(--youtube-thumbnail-background-color);
      background-image: var(--thumbnail-url);
      transition: box-shadow 0.3s ease;

      &:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      }
    }
    
  }
  @media screen and (min-width: 768px) {
    // Add media query to adjust layout for larger screens
    .YoutubeLink {
      width: 50%;
      height: 75%;
    }
  }
}

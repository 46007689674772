.DrKhanPicContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
      
}
  
.DrKhanPic {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
  
@media screen and (max-width: 768px) {
    .DrKhanPic 
    {
      width: 100%;
      height: 100%;
      object-fit: contain;
      padding-bottom: 10rem; 
    }
}
  